<template>
  <div class="platform" :class="{ invalid: data.status === 3 }">
    <div class="main">
      <el-image fit="cover" :src="data.logoUrl" class="logo">
        <img
          class="error-img"
          slot="error"
          src="../../../../assets/icon_chain.png"
        />
      </el-image>
      <div class="text">
        <div class="head">
          <strong>{{ data.name }}</strong>
          <span :class="{ orange: data.status === 0 }">{{
            statusLabelMap[data.status]
          }}</span>
        </div>
        <div class="desc">
          联系信息：{{ data.contacterName }}，{{
            data.contacterPhone | formatPhone
          }}
          <i>|</i> 创建信息：{{ data.inUser }}，{{
            data.inDate | formatDate(9)
          }}
        </div>
      </div>
    </div>
    <div class="btn-group">
      <el-button size="small" @click="$emit('edit-click')">编辑</el-button>
      <el-button size="small" @click="changeChannelPlatformStatus">{{
        data.status === 3 ? "重新开通" : "关闭"
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { changeChannelPlatformStatus } from "@/api/channel";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      statusLabelMap: {
        0: "审核中",
        // 1: '正常',
        2: "锁定",
        3: "已关闭"
      }
    };
  },
  methods: {
    // 关闭平台 重新开通
    async changeChannelPlatformStatus() {
      const { status, companyId } = this.data;
      const res = await changeChannelPlatformStatus({
        id: companyId,
        status: status === 3 ? 0 : 3
      });
      if (res) this.$emit("success");
    }
  }
};
</script>

<style scoped lang="scss">
.platform {
  padding: 20px;
  border-radius: 10px;
  background: #f5f5f5;
  @include flex-yc;
  &.invalid {
    opacity: 0.7;
  }
  .main {
    flex: 1;
    @include flex-yc;
    .logo {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      background: #64d4d4;
      border-radius: 5px;
      .error-img {
        width: 70%;
        height: 70%;
        margin: 15% auto;
      }
    }
    .text {
      .head {
        line-height: 24px;
        strong {
          font-size: 18px;
        }
        span {
          margin-left: 10px;
          color: #999;
        }
      }
      .desc {
        color: #999;
        i {
          padding: 0 10px;
        }
      }
    }
  }
  .btn-group {
  }
}
</style>
