<template>
  <div class="distribution-home base-container">
    <div class="content">
      <div class="head">
        <p class="badge">
          <img src="../../../assets/pcn_app_logo.png" />房猫PCN合作网络
        </p>
        <div class="text">
          <h2>新房营销服务平台</h2>
          <p>报备带看 私域运营 PCN渠道 消息群发</p>
        </div>
        <div class="bg-img"></div>
      </div>
      <div class="desc">
        <h3>房猫PCN合作网络</h3>
        <p>
          房猫PCN合作网络（Partner Cooperate
          Network）是房猫以经纪公司、独立经纪人为基础建立的合伙人合作网络，连接房产交易环节上的所有人，进行有序合作，提高交易和服务的效率，形成良性循环；
          通过房易宝分销平台申请入驻房猫PCN合作网络，建立新房营销服务平台，拓展合作渠道，实现以PCN渠道为中心的项目展示、营销推广、报备带看、认购签约、佣金结算等一站式营销服务，实现与合作渠道更好的连接与沟通。
        </p>
      </div>
      <div class="platform" v-if="channelPlatformInfo">
        <channel-platform
          v-if="channelPlatformInfo.open"
          :data="channelPlatformInfo"
          @edit-click="editDialogVisible = true"
          @success="getChannelPlatform"
        ></channel-platform>
        <div class="empty" v-else-if="isLoaded">
          <div class="text">
            <p class="title">开通条件</p>
            <p>①、分销平台运营商为VIP会员</p>
            <p>②、分销平台可分销项目不低于3个</p>
            <p>③、认可并严格遵循《房猫P+运营规范》</p>
          </div>
          <el-button
            type="primary"
            size="small"
            @click="editDialogVisible = true"
            >申请开通</el-button
          >
        </div>
      </div>
    </div>
    <c-edit-dialog
      :visible.sync="editDialogVisible"
      :title="channelPlatformInfo?.open ? '编辑平台' : '申请开通'"
      :data="channelPlatformInfo"
      @success="getChannelPlatform"
      width="600px"
    ></c-edit-dialog>
  </div>
</template>

<script>
import ChannelPlatform from "./_components/channelPlatform";
import { getChannelPlatform } from "@/api/channel";
import CEditDialog from "./_components/edit";
export default {
  components: {
    ChannelPlatform,
    CEditDialog
  },
  data() {
    return {
      editDialogVisible: false,
      channelPlatformInfo: {},
      isLoaded: false
    };
  },
  created() {
    this.getChannelPlatform();
  },
  methods: {
    // 获取渠道平台
    async getChannelPlatform() {
      const res = await getChannelPlatform();
      this.channelPlatformInfo = res || null;
      this.isLoaded = true;
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  max-width: 1200px;
  margin: 0 auto;
  .head {
    width: 100%;
    height: 200px;
    position: relative;
    border-radius: 10px;
    background: #e71522;
    @include flex-xyc;
    .bg-img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.3;
      background: url(https://cdnoss.fangyibao.cn/pcn_applet/bg_shouye.png)
        no-repeat center top;
    }
    .badge {
      position: absolute;
      left: 10px;
      top: 10px;
      color: #fff;
      z-index: 1;
      @include flex-yc;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .text {
      position: relative;
      color: #fff;
      z-index: 1;
      @include flex-xyc2;
      h2 {
        font-size: 30px;
      }
      p {
        padding-top: 10px;
        color: #fff;
        opacity: 0.7;
      }
    }
  }
  .desc {
    margin-top: 20px;
    h3 {
      font-size: 20px;
    }
    p {
      margin-top: 6px;
      font-size: 15px;
      color: #666;
      line-height: 1.5;
    }
  }
  .platform {
    margin-top: 20px;
  }
}
.empty {
  padding: 20px;
  border-radius: 10px;
  background: #f5f5f5;
  @include flex-ycb;
  .text {
    p:not(.title) {
      margin-top: 5px;
      color: #999;
    }
  }
}
</style>
