var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "platform",
    class: {
      invalid: _vm.data.status === 3
    }
  }, [_c('div', {
    staticClass: "main"
  }, [_c('el-image', {
    staticClass: "logo",
    attrs: {
      "fit": "cover",
      "src": _vm.data.logoUrl
    }
  }, [_c('img', {
    staticClass: "error-img",
    attrs: {
      "slot": "error",
      "src": require("../../../../assets/icon_chain.png")
    },
    slot: "error"
  })]), _c('div', {
    staticClass: "text"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('strong', [_vm._v(_vm._s(_vm.data.name))]), _c('span', {
    class: {
      orange: _vm.data.status === 0
    }
  }, [_vm._v(_vm._s(_vm.statusLabelMap[_vm.data.status]))])]), _c('div', {
    staticClass: "desc"
  }, [_vm._v(" 联系信息：" + _vm._s(_vm.data.contacterName) + "，" + _vm._s(_vm._f("formatPhone")(_vm.data.contacterPhone)) + " "), _c('i', [_vm._v("|")]), _vm._v(" 创建信息：" + _vm._s(_vm.data.inUser) + "，" + _vm._s(_vm._f("formatDate")(_vm.data.inDate, 9)) + " ")])])], 1), _c('div', {
    staticClass: "btn-group"
  }, [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('edit-click');
      }
    }
  }, [_vm._v("编辑")]), _c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.changeChannelPlatformStatus
    }
  }, [_vm._v(_vm._s(_vm.data.status === 3 ? "重新开通" : "关闭"))])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }