var render = function render(){
  var _vm$channelPlatformIn;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "distribution-home base-container"
  }, [_c('div', {
    staticClass: "content"
  }, [_vm._m(0), _vm._m(1), _vm.channelPlatformInfo ? _c('div', {
    staticClass: "platform"
  }, [_vm.channelPlatformInfo.open ? _c('channel-platform', {
    attrs: {
      "data": _vm.channelPlatformInfo
    },
    on: {
      "edit-click": function editClick($event) {
        _vm.editDialogVisible = true;
      },
      "success": _vm.getChannelPlatform
    }
  }) : _vm.isLoaded ? _c('div', {
    staticClass: "empty"
  }, [_c('div', {
    staticClass: "text"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v("开通条件")]), _c('p', [_vm._v("①、分销平台运营商为VIP会员")]), _c('p', [_vm._v("②、分销平台可分销项目不低于3个")]), _c('p', [_vm._v("③、认可并严格遵循《房猫P+运营规范》")])]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": function click($event) {
        _vm.editDialogVisible = true;
      }
    }
  }, [_vm._v("申请开通")])], 1) : _vm._e()], 1) : _vm._e()]), _c('c-edit-dialog', {
    attrs: {
      "visible": _vm.editDialogVisible,
      "title": (_vm$channelPlatformIn = _vm.channelPlatformInfo) !== null && _vm$channelPlatformIn !== void 0 && _vm$channelPlatformIn.open ? '编辑平台' : '申请开通',
      "data": _vm.channelPlatformInfo,
      "width": "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogVisible = $event;
      },
      "success": _vm.getChannelPlatform
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "head"
  }, [_c('p', {
    staticClass: "badge"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/pcn_app_logo.png")
    }
  }), _vm._v("房猫PCN合作网络 ")]), _c('div', {
    staticClass: "text"
  }, [_c('h2', [_vm._v("新房营销服务平台")]), _c('p', [_vm._v("报备带看 私域运营 PCN渠道 消息群发")])]), _c('div', {
    staticClass: "bg-img"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desc"
  }, [_c('h3', [_vm._v("房猫PCN合作网络")]), _c('p', [_vm._v(" 房猫PCN合作网络（Partner Cooperate Network）是房猫以经纪公司、独立经纪人为基础建立的合伙人合作网络，连接房产交易环节上的所有人，进行有序合作，提高交易和服务的效率，形成良性循环； 通过房易宝分销平台申请入驻房猫PCN合作网络，建立新房营销服务平台，拓展合作渠道，实现以PCN渠道为中心的项目展示、营销推广、报备带看、认购签约、佣金结算等一站式营销服务，实现与合作渠道更好的连接与沟通。 ")])]);

}]

export { render, staticRenderFns }