import request from "@/utils/request";

// 查询渠道平台
export function getChannelPlatform(data) {
  return request({
    url: `/api/v1/platform/web/channel/manage/get`,
    data
  });
}

// 开通渠道平台
export function openChannelPlatform(data) {
  return request({
    url: `/api/v1/platform/web/channel/manage/opening`,
    data
  });
}

// 查询pcn渠道
export function getPcnChannelList(data) {
  return request({
    url: `/api/v1/platform/web/channel/manage/pcn/channel/list`,
    data
  });
}

// 修改渠道平台状态
export function changeChannelPlatformStatus(data) {
  return request({
    url: `/api/v1/platform/web/channel/manage/status`,
    data
  });
}

// 修改pcn渠道状态
export function changePcnChannelStatus(data) {
  return request({
    url: `/api/v1/platform/web/channel/manage/join/channel/status`,
    data
  });
}

// 移除pcn渠道
export function removePcnChannel(data) {
  return request({
    url: `/api/v1/platform/web/channel/manage/join/channel/remove`,
    data
  });
}

// 获取渠道公司列表
export function getMechanismList(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/company/list`,
    data,
    method: "GET"
  });
}

// 获取渠道人员列表
export function getMechanismUser(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/user`,
    data,
    method: "GET"
  });
}

//获取渠道公司详情
export const getChannelCompanyDetail = data =>
  request({
    url: `/api/v1/platform/distribution/web/mechanism/detail`,
    data,
    method: "GET"
  });

// 渠道公司批量删除
export const mechanismBatchDelete = data =>
  request({
    url: `/api/v1/platform/distribution/web/mechanism/batch/delete`,
    data
  });

// 渠道公司批量状态修改
export const mechanismBatchStatus = data =>
  request({
    url: `/api/v1/platform/distribution/web/mechanism/batch/status`,
    data
  });

// 获取经纪人列表
export function getMechanismAgent(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/agent/user/list`,
    method: "get",
    data
  });
}
// 获取跟进记录列表
export function getMechanismFollow(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/log/follow/list`,
    data
  });
}

// 查询pcn渠道
export function getPcnChannelCompanyList(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/pcn/member/list`,
    data,
    method: "GET"
  });
}

// PCN加入渠道公司申请移除
export function removePcnChannelCompany(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/pcn/member/remove`,
    data
  });
}

// PCN加入渠道公司申请状态修改
export function changePcnChannelCompanyStatus(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/pcn/member/status`,
    data
  });
}

// 跟进记录，范围筛选
export function getMechanismExtList(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/log/log/ext/list`,
    method: "get",
    data
  });
}

//校验渠道公司名字是否存在
export const validateCompanyFullName = data =>
  request({
    url: "/api/v1/platform/distribution/web/mechanism/validate/fullname",
    data,
    showLoading: false
  });

//校验渠道公司简称是否存在
export const validateCompanyName = data =>
  request({
    url: "/api/v1/platform/distribution/web/mechanism/validate/name",
    data,
    showLoading: false
  });

//渠道人员选择
export const getChannelStaffList = params =>
  request({
    url: "/api/v1/platform/distribution/web/mechanism/user",
    params,
    method: "GET"
  });

// 编辑/创建渠道 或者 门店
export const editChannelCompany = data =>
  request({
    url: "/api/v1/platform/distribution/web/mechanism/create",
    data
  });

// 获取经纪人列表
export function getStoreListByCompany(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mechanism/${data.id}/branch/list`,
    method: "get",
    data
  });
}
