var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    attrs: {
      "close-on-click-modal": false
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-position": "left",
      "label-width": "100px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "组织标识",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('f-upload', {
    attrs: {
      "required": "",
      "type": 2,
      "size": "mini",
      "default-file-list": _vm.formData.logoUrl ? [_vm.formData.logoUrl] : undefined
    },
    on: {
      "on-success": _vm.logoUploadSuccess
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "组织名称",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "联系人",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('div', {
    staticClass: "item-group"
  }, [_c('el-input', {
    staticClass: "name",
    attrs: {
      "placeholder": "姓名"
    },
    model: {
      value: _vm.formData.contacterName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "contacterName", $$v);
      },
      expression: "formData.contacterName"
    }
  }), _c('el-form-item', {
    attrs: {
      "prop": "contacterPhone",
      "rules": [_vm.$formRules.required(), _vm.$formRules.tel]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "联系电话",
      "maxlength": "11"
    },
    model: {
      value: _vm.formData.contacterPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "contacterPhone", $$v);
      },
      expression: "formData.contacterPhone"
    }
  })], 1)], 1)])], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update:visible', false);
      }
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("确定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }