<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" :close-on-click-modal="false">
    <el-form
      ref="form"
      :model="formData"
      label-position="left"
      label-width="100px"
    >
      <el-form-item label="组织标识" :rules="[$formRules.required()]">
        <f-upload
          required
          :type="2"
          size="mini"
          :default-file-list="formData.logoUrl ? [formData.logoUrl] : undefined"
          @on-success="logoUploadSuccess"
        ></f-upload>
      </el-form-item>
      <el-form-item label="组织名称" :rules="[$formRules.required()]">
        <el-input placeholder="请输入" v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="联系人" :rules="[$formRules.required()]">
        <div class="item-group">
          <el-input
            class="name"
            placeholder="姓名"
            v-model="formData.contacterName"
          ></el-input>
          <el-form-item
            prop="contacterPhone"
            :rules="[$formRules.required(), $formRules.tel]"
          >
            <el-input
              placeholder="联系电话"
              maxlength="11"
              v-model="formData.contacterPhone"
            ></el-input>
          </el-form-item>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="$emit('update:visible', false)">取消</el-button>
      <el-button type="primary" @click="submitForm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FUpload from "@/components/upload";
import { openChannelPlatform } from "@/api/channel";
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  components: {
    FUpload
  },
  data() {
    return {
      formData: {
        contacterName: "", // 联系人姓名
        contacterPhone: "", // 联系人手机
        id: null,
        status: null,
        logoUrl: "", // 组织标示
        name: "" //组织名称
      }
    };
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        for (const key in this.formData) {
          this.formData[key] = val[key];
        }
        this.formData.id = val.companyId;
      }
    }
  },
  methods: {
    logoUploadSuccess(fileList) {
      this.formData.logoUrl = fileList[0].url;
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const res = await openChannelPlatform(this.formData);
        if (res) {
          this.$showSuccess("操作成功");
          this.$emit("success");
          this.$emit("update:visible", false);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.item-group {
  display: flex;
  .name {
    width: 150px;
    margin-right: 10px;
  }
  ::v-deep {
    .el-form-item {
      flex: 1;
    }
  }
}
</style>
